.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}
/* Hide mobile nav toggle on larger screens (web/desktop view) */
@media (min-width: 1090px) {
  .mobile-nav-toggle {
    display: none;
  }
}

.Link {
  text-decoration: none;
}
.homebanner img {
  height: 550px;
  width: 100%;
  object-fit: cover;
}
@media (max-width: 768px) {
  .homebanner img {
    height: 350px;
  }
}
.about-img {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.about-img-1 {
  height: 50vh;
}

/* .home-about{
  display: flex;
  flex-direction: row;
  align-items: center;
} */

.home-img {
  height: 65vh;
  width: 100%;
}

/*------------------------- events ----------------- */

/* Default styles for web */
.projcard-container {
  margin: 50px 0;
}

.projcard-container,
.projcard-container * {
  box-sizing: border-box;
}

.projcard-container {
  margin-left: auto;
  margin-right: auto;
  width: 1000px;
}

.projcard {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: 300px;
  margin-bottom: 40px;
  border-radius: 10px;
  background-color: #fff;
  border: 2px solid #ddd;
  font-size: 18px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0 4px 21px -12px rgba(0, 0, 0, 0.66);
  transition: box-shadow 0.2s ease, transform 0.2s ease;
}

.projcard:hover {
  box-shadow: 0 34px 32px -33px rgba(0, 0, 0, 0.18);
  transform: translate(0px, -3px);
}

.projcard::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(-70deg, #424242, transparent 50%);
  opacity: 0.07;
}

.projcard:nth-child(2n)::before {
  background-image: linear-gradient(-250deg, #424242, transparent 50%);
}

.projcard-innerbox {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.projcard-img {
  position: absolute;
  height: 300px;
  width: 400px;
  top: 0;
  left: 0;
  transition: transform 0.2s ease;
}

.projcard:nth-child(2n) .projcard-img {
  left: initial;
  right: 0;
}

.projcard:hover .projcard-img {
  transform: scale(1.05) rotate(1deg);
}

.projcard:hover .projcard-bar {
  width: 70px;
}

.projcard-textbox {
  position: absolute;
  top: 7%;
  bottom: 7%;
  left: 430px;
  width: calc(100% - 470px);
  font-size: 17px;
}

.projcard:nth-child(2n) .projcard-textbox {
  left: initial;
  right: 430px;
}

.projcard-textbox::before,
.projcard-textbox::after {
  content: "";
  position: absolute;
  display: block;
  background: #ff0000bb;
  background: #fff;
  top: -20%;
  left: -55px;
  height: 140%;
  width: 60px;
  transform: rotate(8deg);
}

.projcard:nth-child(2n) .projcard-textbox::before {
  display: none;
}

.projcard-textbox::after {
  display: none;
  left: initial;
  right: -55px;
}

.projcard:nth-child(2n) .projcard-textbox::after {
  display: block;
}

.projcard-textbox * {
  position: relative;
}

.projcard-title {
  font-family: "Voces", "Open Sans", arial, sans-serif;
  font-size: 24px;
}

.projcard-subtitle {
  font-family: "Voces", "Open Sans", arial, sans-serif;
  color: #888;
}

.projcard-bar {
  left: -2px;
  width: 50px;
  height: 5px;
  margin: 10px 0;
  border-radius: 5px;
  background-color: #424242;
  transition: width 0.2s ease;
}

.projcard-blue .projcard-bar {
  background-color: #0088ff;
}

.projcard-blue::before {
  background-image: linear-gradient(-70deg, #0088ff, transparent 50%);
}

.projcard-blue:nth-child(2n)::before {
  background-image: linear-gradient(-250deg, #0088ff, transparent 50%);
}

.projcard-red .projcard-bar {
  background-color: #d62f1f;
}

.projcard-red::before {
  background-image: linear-gradient(-70deg, #d62f1f, transparent 50%);
}

.projcard-red:nth-child(2n)::before {
  background-image: linear-gradient(-250deg, #d62f1f, transparent 50%);
}

.projcard-green .projcard-bar {
  background-color: #40bd00;
}

.projcard-green::before {
  background-image: linear-gradient(-70deg, #40bd00, transparent 50%);
}

.projcard-green:nth-child(2n)::before {
  background-image: linear-gradient(-250deg, #40bd00, transparent 50%);
}

.projcard-yellow .projcard-bar {
  background-color: #f5af41;
}

.projcard-yellow::before {
  background-image: linear-gradient(-70deg, #f5af41, transparent 50%);
}

.projcard-yellow:nth-child(2n)::before {
  background-image: linear-gradient(-250deg, #f5af41, transparent 50%);
}

.projcard-orange .projcard-bar {
  background-color: #ff5722;
}

.projcard-orange::before {
  background-image: linear-gradient(-70deg, #ff5722, transparent 50%);
}

.projcard-orange:nth-child(2n)::before {
  background-image: linear-gradient(-250deg, #ff5722, transparent 50%);
}

.projcard-brown .projcard-bar {
  background-color: #c49863;
}

.projcard-brown::before {
  background-image: linear-gradient(-70deg, #c49863, transparent 50%);
}

.projcard-brown:nth-child(2n)::before {
  background-image: linear-gradient(-250deg, #c49863, transparent 50%);
}

.projcard-grey .projcard-bar {
  background-color: #424242;
}

.projcard-grey::before {
  background-image: linear-gradient(-70deg, #424242, transparent 50%);
}

.projcard-grey:nth-child(2n)::before {
  background-image: linear-gradient(-250deg, #424242, transparent 50%);
}

.projcard-customcolor .projcard-bar {
  background-color: var(--projcard-color);
}

.projcard-customcolor::before {
  background-image: linear-gradient(
    -70deg,
    var(--projcard-color),
    transparent 50%
  );
}

.projcard-customcolor:nth-child(2n)::before {
  background-image: linear-gradient(
    -250deg,
    var(--projcard-color),
    transparent 50%
  );
}

.projcard-description {
  z-index: 10;
  font-size: 15px;
  color: #424242;
  height: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.projcard-tagbox button {
  bottom: 3%;
  font-size: 25px;
  cursor: default;
  user-select: none;
  padding: 10px 20px;
  border-radius: 25px;
  text-decoration: none;
  border: none;
  outline: none;
  background: purple;
  box-shadow: 0 4px 21px -12px rgba(0, 0, 0, 0.66);
  transition: box-shadow 0.2s ease, transform 0.2s ease;
}

.projcard-tagbox a {
  text-decoration: none;
  color: #ddd;
}
/* Mobile styles */
@media (max-width: 768px) {
  .projcard-container {
    width: 100%;
    padding: 0 15px;
  }
  .projcard {
    flex-direction: column;
    height: 630px;
  }

  .projcard-img {
    width: 100%;
    height: 450px;
    position: static;
  }

  .projcard-textbox {
    position: static;
    width: 100%;
    padding: 15px;
  }
  .projcard-textbox::before {
    display: contents;
  }
}

@media (max-width: 576px) {
  .projcard-container {
    width: 100%;
    padding: 0 15px;
  }
  .projcard {
    flex-direction: column;
    height: 470px;
  }
  .projcard-textbox {
    position: static;
    width: 100%;
    padding: 15px;
  }
  .projcard-img {
    width: 100%;
    height: auto;
    position: static;
  }
}

/*  -----------------------gallery-------------------  */
.gallery {
  margin: auto;
  max-width: 1200px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 10px;
}

.gallery-item {
  flex: 1 1 calc(33% - 20px);
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s;
}

.gallery-item img {
  width: 100%;
  height: 300px;
  display: block;
  object-fit: content;
  /* opacity: 0.6; */
}

.gallery-item:hover {
  transform: scale(1.05);
}

.gallery-item:hover img {
  opacity: 1;
}

.modal {
  display: none;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.8);
}

.modal-content {
  display: flex;
  margin: 10% auto;
  padding: 20px;
  background: #fff;
  max-width: 70%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.modal-left img {
  max-width: 100%;
  height: auto;
  float: left;
  border-radius: 10px;
}

.modal-right {
  margin-left: 20px;
  padding: 0 2em;
  width: 40%;
}

.modal-right p {
  margin: 0;
}

.close {
  position: absolute;
  top: 15px;
  right: 30px;
  font-size: 40px;
  font-weight: bold;
  color: white;
  cursor: pointer;
}

.nav-btn {
  background-color: #333;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin-top: 20px;
  cursor: pointer;
  border-radius: 5px;
}

.nav-btn:hover {
  background-color: #555;
}

@media (max-width: 768px) {
  .gallery-item {
    flex: 1 1 calc(50% - 20px);
  }

  .modal-content {
    flex-direction: column;
  }

  .modal-right {
    margin-left: 0;
    margin-top: 20px;
    width: 80%;
    padding: 0;
  }

  .nav-btn {
    margin-top: 10px;
  }
}

@media (max-width: 480px) {
  .gallery-item {
    flex: 1 1 100%;
  }
}

.footer-align {
  display: flex;
  justify-content: center;
}
#vision-banner {
  background-image: url("../public/assets/img/banner/school2.jpg");
  /* background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../public/assets/img/banner/Logo.png"); */
  background-size: contain; /* or "cover" if you want to cover the entire area */
  background-position: center;
  background-repeat: no-repeat;
  height: 310px;
  width: 100%;
  background-color: #eaeaed;
}

#About-banner {
  background-image: url("../public/assets/img/banner/aboutbanner.jpg");
  /* background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../public/assets/img/banner/Logo.png"); */
  background-size: contain; /* or "cover" if you want to cover the entire area */
  background-position: center;
  background-repeat: no-repeat;
  height: 310px;
  width: 100%;
  background-color: #eaeaed;
}
@media (max-width: 768px) {
  #About-banner {
    /* background-image: url("/assets/img/banner/banner1.jpg"); */

    background-size: cover; /* or "cover" if you want to cover the entire area */
    background-position: center;
    background-repeat: no-repeat;
    height: 409px;
    width: 100%;
    background-color: #eaeaed;
  }
  #vision-banner {
    /* background-image: url("/assets/img/banner/banner1.jpg"); */

    background-size: cover; /* or "cover" if you want to cover the entire area */
    background-position: center;
    background-repeat: no-repeat;
    height: 409px;
    width: 100%;
    background-color: #eaeaed;
  }
}

#event-banner {
  background-image: url("../public/assets/img/banner/eventbanner.jpg");
  /* background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../public/assets/img/banner/eventbanner.jpg"); */
  background-size: contain; /* or "cover" if you want to cover the entire area */
  background-position: center;
  background-repeat: no-repeat;
  height: 310px;
  width: 100%;
}
@media (max-width: 768px) {
  #event-banner {
    /* background-image: url("/assets/img/banner/banner1.jpg"); */

    background-size: cover; /* or "cover" if you want to cover the entire area */
    background-position: center;
    background-repeat: no-repeat;
    height: 100px;
    width: 100%;
  }
}

#Gallery-banner {
  background-image: url("../public/assets/img/banner/gallerybanner.jpg");
  /* background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../public/assets/img/banner/gallerybanner.jpg"); */
  background-size: contain; /* or "cover" if you want to cover the entire area */
  background-position: center;
  background-repeat: no-repeat;
  height: 360px;
  width: 100%;
}
@media (max-width: 768px) {
  #Gallery-banner {
    /* background-image: url("/assets/img/banner/banner1.jpg"); */

    background-size: cover; /* or "cover" if you want to cover the entire area */
    background-position: center;
    background-repeat: no-repeat;
    height: 100px;
    width: 100%;
  }
}

#Contact-banner {
  background-image: url("../public/assets/img/banner/contactbanner.jpg");
  /* background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../public/assets/img/banner/contactbanner.jpg"); */
  background-size: contain; /* or "cover" if you want to cover the entire area */
  background-position: center;
  background-repeat: no-repeat;
  height: 300px;
  width: 100%;
}
@media (max-width: 768px) {
  #Contact-banner {
    /* background-image: url("/assets/img/banner/banner1.jpg"); */

    background-size: cover; /* or "cover" if you want to cover the entire area */
    background-position: center;
    background-repeat: no-repeat;
    height: 100px;
    width: 100%;
  }
}
/* ----------------------------------------------Facilities--------------------------- */
#Facilities-banner {
  background-image: url("../public/assets/img/banner/banner1.jpg");

  /* background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../public/assets/img/banner/banner1.jpg"); */
  background-size: contain; /* or "cover" if you want to cover the entire area */
  background-position: center;
  background-repeat: no-repeat;
  height: 270px;
  width: 100%;
}

.Facilities {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Facilities-content {
  width: 60%;
}
.Facilities-content ul {
  padding-left: 20px;
}
.Facilities-content li {
  line-height: 30px;
  list-style-type: none;
  padding-left: 20px;
}
/* In your CSS file */
.custom-ul {
  list-style: none; /* Remove default bullets */
  padding: 0; /* Remove padding */
}

.custom-li {
  position: relative; /* Position the pseudo-element */
  padding-left: 1.5em; /* Add space for the custom bullet */
}

.Facilities-image {
  width: 35%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 50px;
}
.Facilities-image img {
  height: 200px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 10px;
}
#Facilitiesheading {
  font-size: 2rem;
  font-weight: 700;
}
@media (max-width: 768px) {
  #Facilities-banner {
    /* background-image: url("/assets/img/banner/banner1.jpg"); */

    background-size: cover; /* or "cover" if you want to cover the entire area */
    background-position: center;
    background-repeat: no-repeat;
    height: 100px;
    width: 100%;
  }
  .Facilities {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .Facilities-content {
    width: 100%;
  }
  .Facilities-content h3 {
    text-align: center;
  }
}
/* ----------------------------------------------Faculty--------------------------- */
#Faculty-banner {
  /* background-image: url("/assets/img/banner/banner1.jpg"); */
  background-image: url("../public/assets/img/banner/banner1.jpg");
  background-size: contain; /* or "cover" if you want to cover the entire area */
  background-position: center;
  background-repeat: no-repeat;
  height: 270px;
  width: 100%;
}

.Faculty {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.Faculty-content {
  width: 60%;
}
.Faculty-image {
  width: 35%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 50px;
}
.Faculty-image img {
  height: 200px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 10px;
}
#Facultyheading {
  font-size: 2rem;
  font-weight: 700;
}

@media (max-width: 768px) {
  #Faculty-banner {
    /* background-image: url("/assets/img/banner/banner1.jpg"); */
    background-image: url("../public/assets/img/banner/banner1.jpg");
    background-size: cover; /* or "cover" if you want to cover the entire area */
    background-position: center;
    background-repeat: no-repeat;
    height: 100px;
    width: 100%;
  }
  .Faculty {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .Faculty-content {
    width: 100%;
  }
  .Faculty-content h3 {
    text-align: center;
  }
}
/* ----------------------------------------------homesecond--------------------------- */

.homesecond-card {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 6px;
  margin: 0 0 1rem 0;
  display: flex;
  flex-direction: column;
}

.homesecond-card__img {
  width: 100%;
  height: 15rem;
  border-radius: 6px 6px 0 0;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
}
.homesecond-cart__content {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 auto;
  p {
    flex: 1 1 auto;
  }
  a {
    display: inline-block;
    background-color: $background-color_1;
    color: rgba(36, 28, 105, 255);
    text-decoration: none;

    border-radius: 50px;
    margin: 0rem 0 0 0;
  }
}
@media screen and (min-width: 991px) {
  .homesecond-cards {
    display: flex;
    flex-direction: row;

    .homesecond-card {
      flex: 1;
      margin: 1%;
    }
  }
}

.homesecond-card .read-more {
  color: rgba(36, 28, 105, 255);
  font-family: var(--heading-font);
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 1px;
  padding: 5px 14px 5px 14px;
  border-radius: 50px;
  transition: 0.3s;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.homesecond-card .read-more i {
  font-size: 18px;
  margin-left: 5px;
  line-height: 0;
  transition: 0.3s;
  margin-top: 5px;
}

.homesecond-card .read-more:hover {
  background: color-mix(in srgb, rgba(36, 28, 105, 255), transparent 20%);
  color: white;
  padding-right: 19px;
}

.homesecond-card .read-more:hover i {
  margin-left: 10px;
  color: white;
}

/* ----------------------------------------------home-gallery--------------------------- */

.home-gallery {
  position: relative;
  display: grid;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 50px;
}
.home-galleryheader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.home-gallery::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("../public/assets/img/homepage/homegallerybanner.jpg");
  background-size: cover;
  background-position: center;
  opacity: 0.2; /* Adjust the opacity as needed */
  z-index: -1; /* Ensure the background image stays behind the content */
}

.home-gallery img {
  height: 230px;
  object-fit: fill;
  margin: 10px;
  z-index: 1; /* Ensure the images stay on top of the background */
}

.home-gallery .container {
  position: relative; /* Ensure the container's content stays on top */
  z-index: 1;
}
.home-galleryheader .read-more {
  color: white;
  background-color: rgba(36, 28, 105, 255);
  font-family: var(--heading-font);
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 1px;
  padding: 5px 14px 5px 14px;
  border-radius: 50px;
  transition: 0.3s;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.home-galleryheader .read-more i {
  font-size: 18px;
  margin-left: 5px;
  line-height: 0;
  transition: 0.3s;
  margin-top: 5px;
}

.home-galleryheader .read-more:hover {
  background: color-mix(in srgb, rgba(36, 28, 105, 255), transparent 20%);
  color: white;
  padding-right: 19px;
}

.home-galleryheader .read-more:hover i {
  margin-left: 10px;
  color: white;
}
@media (max-width: 768px) {
  .home-galleryheader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
  }
  .home-gallery img {
    height: 180px;
    object-fit: fill;
    margin: 10px;
    z-index: 1; /* Ensure the images stay on top of the background */
  }
}
/* ----------------------------------------------PRINCIPAL-DESK--------------------------- */

.PRINCIPAL-DESK {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 30px;
}
.PRINCIPAL-DESK-content {
  width: 70%;
  height: 250px;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url("../public/assets/img/homepage/homedesk.jpg");
  background-size: cover;
  background-position: center;
  padding: 50px;
  color: white;
  font-size: 18px;
}
.PRINCIPAL-DESK-content h3 {
  color: white;
  font-size: 22px;
}

.PRINCIPAL-DESK-student {
  width: 30%;
  height: 250px;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../public/assets/img/gallery/gallery7.jpg");
  background-size: cover;
  background-position: center;
  color: white;
  padding: 80px;
  font-size: 32px;
  text-align: center;
}
@media (max-width: 768px) {
  .PRINCIPAL-DESK {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
  }
  .PRINCIPAL-DESK-content {
    width: 100%;

    padding: 20px;

    font-size: 16px;
  }
  .PRINCIPAL-DESK-content h3 {
    color: white;
    font-size: 22px;
  }

  .PRINCIPAL-DESK-student {
    width: 100%;
  }
}

/* ----------------------------------------------contact-main--------------------------- */

.contact-main {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.contactform {
  width: 60%;
}
.contactimg-card {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 6px;
  margin: 0 0 1rem 0;
  display: flex;
  flex-direction: column;
}

.contactimg-card__img {
  width: 100%;
  height: 15rem;
  border-radius: 6px 6px 0 0;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
}
.contact .info-item + .info-item {
  margin-top: 0px;
}
@media screen and (min-width: 991px) {
  .contactimg-cards {
    display: flex;
    flex-direction: column;
    width: 40%;
    align-items: flex-start;

    .homesecond-card {
      flex: 1;
      margin: 1%;
    }
  }
}

@media (max-width: 768px) {
  .contact-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
  .contact .info-item + .info-item {
    margin-bottom: 25px;
  }
}

.Enquiry {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

.Enquiryform-main {
  background: linear-gradient(to bottom, #00000024, #00000024),
    url("../public/assets/img/gallery/gallery7.jpg") no-repeat center;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .Enquirymain-wrapper {
    border-radius: 10px;
    padding: 45px;
    width: 40%;
    box-shadow: 0 0 5px 5px #00000020;
    backdrop-filter: blur(5px);
    background-color: #ffffff85;
    @media screen and (max-width: 991px) {
      width: 70%;
    }
    @media screen and (max-width: 767px) {
      width: 90%;
    }
    .Enquiryform-head {
      font-size: 30px;
      line-height: 40px;
      font-weight: 600;
      text-align: center;
      margin: 0px 0 25px;
    }
    .Enquiryform-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 15px;
      .Enquiryform-card {
        position: relative;
        width: 100%;

        .Enquiryform-input {
          padding: 20px 25px 15px;
          width: 100%;
          border: 1px solid $color-black;
          border-radius: 5px;
          background: transparent;
          outline: none;
          font-size: 20px;
          line-height: 30px;
          font-weight: 400;
          box-sizing: border-box;

          &:valid,
          &:focus {
            border: 1px solid $color-gray;
          }

          &:valid ~ .Enquiryform-label,
          &:focus ~ .Enquiryform-label {
            color: $color-gray;
            top: 30%;
            transform: translateY(-70%);
            font-size: 13px;
            line-height: 23px;
          }

          &:-webkit-autofill,
          &:-webkit-autofill:hover,
          &:-webkit-autofill:focus,
          &:-webkit-autofill:active {
            transition: background-color 9999s ease-in-out 0s;
          }
          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        }

        .Enquiryform-label {
          position: absolute;
          left: 25px;
          top: 50%;
          transform: translateY(-50%);
          pointer-events: none;
          transition: 0.3s;
          margin: 0;
          font-size: 18px;
          line-height: 28px;
          font-weight: 500;
        }
        .Enquiryform-textarea {
          padding: 20px 25px 15px;
          width: 100%;
          border: 1px solid $color-black;
          border-radius: 5px;
          background: transparent;
          outline: none;
          font-size: 20px;
          line-height: 30px;
          font-weight: 400;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          resize: none;
          box-sizing: border-box;

          &:valid,
          &:focus {
            border: 1px solid $color-gray;
          }

          &:valid ~ .Enquiryform-textarea-label,
          &:focus ~ .Enquiryform-textarea-label {
            color: $color-gray;
            top: 18%;
            transform: translateY(-82%);
            font-size: 13px;
            line-height: 23px;
          }

          &:-webkit-autofill,
          &:-webkit-autofill:hover,
          &:-webkit-autofill:focus,
          &:-webkit-autofill:active {
            transition: background-color 9999s ease-in-out 0s;
          }
        }
        .Enquiryform-textarea-label {
          position: absolute;
          left: 25px;
          top: 30%;
          transform: translateY(-70%);
          pointer-events: none;
          transition: 0.3s;
          margin: 0;
          font-size: 18px;
          line-height: 28px;
          font-weight: 500;
        }
      }
    }
  }
}
.Enquirybtn-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 0 0;
}
.Enquirybtn-wrap button {
  padding: 0 32px;
  font-size: 18px;
  line-height: 48px;
  border: 1px solid transparent;
  font-weight: 600;
  border-radius: 6px;
  transition: all 0.5s ease;
  cursor: pointer;
  box-shadow: 0 0 5px 5px #00000020;
}
.Enquirybtn-wrap button:hover {
  border: 1px solid #000;
  background: transparent;
}
/* Grid layout for the gallery */
.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 15px;
  justify-items: center;
}

.gallery-item img {
  width: 300px;
  height: 250px;

  cursor: pointer;
  transition: transform 0.3s;
  object-fit: fill;
}

.gallery-item img:hover {
  transform: scale(1.05);
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
}

.modal-content {
  display: flex;
  justify-content: center;
  background-color: #fff;
  padding: 40px;
  display: flex;
  width: 100%;
  height: 90%;
  flex-direction: row;
  align-items: center;
  margin-top: 10%;
}
.modal-img img {
  width: 100%;
  height: 60% !important;
  margin-top: 30px;
}

.nav-btn {
  background-color: transparent;
  border: none;
  color: black;
  font-size: 30px;
  cursor: pointer;
}

.close {
  position: absolute;
  top: 20px;
  right: 30px;
  font-size: 40px;
  cursor: pointer;
  color: white;
}

.year-section {
  margin-bottom: 40px;
}

.year-section h2 {
  text-align: center;
  margin-bottom: 20px;
}

.modal-content {
  display: flex; /* Use flexbox to align left and right content */
  justify-content: space-between;
}

.nav-buttons {
  display: flex;
  flex-direction: column; /* Stack buttons vertically */
  justify-content: center; /* Center buttons vertically */
}
#nav-btn-left {
  position: absolute;
  top: 225px;
  left: -20px;
}
#nav-btn-right {
  position: absolute;
  top: 225px;
  right: -20px;
}
.modal-right {
  margin-left: 0px;
  padding: 0px 0em;
  width: 0%;
}
.pagination button {
  border: none;
  background: none;
}
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 30px;
}

.pagination-button {
  margin: 0 5px;
  padding: 10px 15px;
  cursor: pointer;
  border: none;
  background: none;
}

.pagination-button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.active {
  font-weight: bold;
  color: #007bff;
}

@media (max-width: 768px) {
  .responsive-modal {
    display: none;
  }
}

.gallery-item {
  position: relative;
  display: inline-block; /* Make sure the container is inline-block to fit the image */
}

.gallery-item .overlay {
  position: absolute;
  top: 10px; /* Adjust as needed */
  left: 10px; /* Adjust as needed */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  color: white; /* Text color */
  padding: 5px; /* Add some padding */
  border-radius: 5px; /* Optional: rounded corners */
  /* Ensure the overlay is always visible */
  display: block; /* Make sure it’s always displayed */
}
.gallery-item .gallery-item-text {
  text-align: center;
  margin-top: 5px;
  font-size: 20px;
}
